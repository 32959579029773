@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply rounded-full text-base font-normal text-brand-primary hover:underline;
  }
  strong {
    @apply font-bold text-black;
  }
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat/Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat/Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat/Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat/ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Montserrat", sans-serif;
}

#root h1,
#root h2,
#root h3,
#root h4,
#root h5,
#root h6 {
  font-family: "Montserrat", sans-serif !important;
}

input[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

input[type="radio"]:focus-visible:before,
input[type="checkbox"]:focus-visible:before {
  @apply absolute -bottom-1 -left-1 -right-1 -top-1 block h-auto w-auto rounded-lg border-2 border-dashed
  border-brand-primary content-[''];
}

[data-radix-popper-content-wrapper] {
  position: absolute !important;
  right: 0px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
